
.certi-container{
  display: flex;
  flex-direction: column;
}

.previous-container {
    align-self: center;
    margin-bottom: 20px;
    margin-top: 165px;
  }
  
  .input-field {
    width: 250px;
    height: 40px;
    border: 1px solid #ccc;
    background: rgb(255, 250, 255);
    border-radius: 10px;
    padding-left: 15px;
    color: #3b3b3b;
    text-decoration: none;
    margin: 10px;
  }

  .input-field:focus {
    outline: none;
    border: 1px solid #b3b3b3;
  }

.loading{
  margin-top: 100px;
  align-self: center;
}

.result-box2 {
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: transform 0.2s;
  background-color: #f0f3f2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.fff {
  display: flex;
  flex-direction: row;
}
.fff2 {
  display: flex;
  flex-direction: column;
}

.result-box2:hover {
  transform: scale(1.02);
}

.name-container {
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
  background-color: #c4dbd2;
  margin-bottom: 8px;
}

.data-container2 {
  padding: 10px;
}

.prev-data{
  display: flex;
  justify-content: space-between;
}

.left-data,
.right-data {
  flex-grow: 1;
}

.left-data p,
.right-data p {
  margin: 5px 0;
}

.left-data{
  margin-left: 20px;
}

.right-data {
 margin-left:  75px;
}


.link {
  text-decoration: none;
  color: #333;
}

.pagination{
  align-self: center;
  margin-top: 10px;
  margin-bottom: 40px;
}

.page-select-button {
  background-color: transparent;
  color: #0a6844;
  border: none;
  min-width: 35px;
  height: 30px;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 600;
}

.page-select-button:last-child {
  margin-right: 0;
}

.page-select-button:hover {
  background-color: #11a76d;
  border-radius: 6px;
  color: #fff;
}

.selected-page {
  border-bottom: 2px solid #0a6844;
  font-weight: bolder;
  font-size: 17px;
}

.certi-text {
  padding-left: 5px;
}

@media (max-width: 35em) {
  .previous-container{
    margin-top: 200px;
    width: 90%;
  }
}