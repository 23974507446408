
  .settings-container {
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .settings-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 550px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
    background-color: #E1EEED;
    margin-top: 160px;
    margin-bottom: 80px;
  }

  .submit-button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }

  .pdf-view-button{
    margin-bottom: 12px;
    border-radius: 6px;
    border: 0.2px solid #d4cfcf;
    padding: 5px;
    background-color: #deeebf;
    font-weight: 500;
    cursor: pointer;
    margin-left: 20px;
  }

  .clinic-data{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .arrow-header{
    display: flex;
    flex-direction: row;
    padding: 15px;
  }

  .arrow-icon{
    color: #555555;
    margin-left: 5px;
    cursor: pointer;
  }

  .settings-label {
    margin-right: auto;
  }

  .settings-checkbox{
    margin-top: 20px;
  }

  .settings-checkbox label{
    margin-left: 5px;
  }

  .delete-button{
    margin-bottom: 12px;
    border-radius: 6px;
    border: 0.2px solid #d4cfcf;
    padding: 5px;
    background-color: #f03636;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    margin-left: 20px;
  }

  .prev-files{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  #errorModal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .modal-input {
    padding: 8px;
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .change-button {
    margin-top: 15px;
    background-color: #eaf5d7b7;
    border: 1px solid #9e9e9e;
    border-radius: 5px;
    padding: 5px;
    width: 30%;
    cursor: pointer;
  }

  .change-button:hover {
    background-color: #defcabc2;
  }

  .delete-user-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 5%;
    max-width: 650px;
    padding: 20px;
    background-color: #E1EEED;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: 'center';
  }

  .delete-user-modal p{
    align-self: self-start;
    margin-bottom: 5px;
  }

  .delete-user{
    margin-top: 15px;
    background-color: transparent;
    color: #f03636;
    border: none;
    cursor: pointer;
    font-size: 15px;
  }

  .data-export-button {
    background-color: transparent;
    border: none;
    color: #065c96;
    cursor: pointer;
  }

  .datepicker-field {
    width: 250px;
    height: 40px;
    border: 1px solid #ccc;
    background: rgb(255, 250, 255);
    border-radius: 10px;
    padding-left: 15px;
    color: #3b3b3b;
    text-decoration: none;
    margin: 10px;
    padding-left: 30px;
  }
  
  .delete-li{
    align-self: self-start;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .forgot-pin-button {
    background-color: transparent;
    border: none;
    color: #054875;
    cursor: pointer;
  }

  @media (max-width: 35em) {
    .settings-form {
      width: 80%;
    }
  }