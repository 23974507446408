.login-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  background-color: #f1f5f4;
}

.login-pic {
  height: 100vh;
  width: 100%;
}

.login-fields {
  width: 100%;
  max-width: 420px;
  margin-right: auto;
  margin-left: auto;
}

.login-field-container {
  background-color: rgb(250, 255, 252);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 15px;
}

.name-logo {
  width: 100px;
}

.login-form {
  margin-top: 20px;
}

.login-label {
  display: block;
  margin-bottom: 10px;
}

.login-input {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-button {
  width: 80%;
  padding: 10px;
  margin-top: 20px;
  background-color: #218838;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #078057;
}

.login-error {
  margin-top: 10px;
  color: #ff0000;
}

.forgot-password-container {
  margin-top: 20px;
}

.forgot-password {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.forgot-password:hover {
  text-decoration: underline;
}

.registration-link {
  text-decoration: none;
}

.login-link {
  margin-top: 20px;
}

.login-link a {
  color: #007bff;
}

.login-link a:hover {
  text-decoration: underline;
}

.forgot-password-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  max-height: 250px;
  margin: auto;
  padding: 20px;
  background-color: #E1EEED;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 50px;
}

.forgot-password-modal p {
  margin-top: 20px;
  margin-bottom: 4px;
}

.success-message p {
  margin-top: 20px;
  color: #078057;
}

.send {
  background-color: transparent;
  color: #1fa377;
  font-weight: bold;
  width: 110px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #1fa377;
  margin-top: 4px;
  cursor: pointer;
  margin-top: 15px;
}

.send:hover {
  background-color: #1fa377;
  color: #fff;
}

.welcome {
  font-size: 21px;
  font-weight: 600;
  color: #1a9169;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.login-field-container h1 {
  margin-bottom: 25px;
}

.impressum-button {
  background-color: transparent;
  border: none;
  margin-top: 20px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 35em) {
  .login-pic {
    display: none;
  }

  .login-field-container {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }
}