.pdf-container {
  margin-left: 20%;
}

.field3 {
  width: 530px;
  height: 720px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10%;
  font-size: 14px;
}

.doc-data {
  text-align: left;
  font-size: smaller;
}

.igazolas-title {
  text-align: center;
  padding-bottom: 8px;
  padding-top: 30px;
}

.child {
  text-align: left;
}

.child-data {
  text-align: left;
  padding: 10px;
}

.data {
  padding-top: 6px;
}

.cont {
  padding: 4px;
}

.cont2 {
  padding: 4px;
  word-wrap: break-word;
  word-break: break-word;
  max-height: 30px;
  overflow: hidden;
}

.doctor {
  text-align: left;
  margin-top: 8px;
  margin-bottom: 20px;
}

.qr-code {
  text-align: right;
  margin-right: 5px;
}

.check {
  margin-top: 10px;
}

.qr-code p:last-child {
  padding-top: 2px;
  padding-bottom: 40px;
  font-size: smaller;
}

.line2 {
  margin-left: 0;
  border: 0.5px solid #6b6b6b;
  width: 100%;
}

.added {
  display: flex;
  justify-content: space-between;
}

.added p {
  font-size: 8px;
  display: inline-block;
  padding-left: 10px;
  margin-right: 25px;
}

.bold-date {
  display: inline;
  font-weight: bold;
}

.logo1 {
  height: 30px;
  margin-right: 10px;
  margin-bottom: 3px;
}

.pdf-generate {
  flex: 1;
  margin-top: 643px;
  margin-left: -140px;
}

.pdf-generate button {
  background-color: #078057;
  color: #fff;
  font-weight: bold;
  width: 140px;
  height: 45px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.law {
  border: 2px solid #6b6b6b;
  padding: 3px;
  margin-top: 8px;
}

@media (max-width: 85em) {
  .pdf-container {
    margin-left: 0;
    margin-top: 15px;
  }
}

@media (max-width: 65em) {
  .pdf-container {
    margin-left: 0;
    margin-top: 15px;
  }
}

@media (max-width: 35em) {
  .pdf-container {
    margin-left: 0;
    margin-top: 4%;
  }

  .field3 {
    width: 91%;
    height: 565px;
    font-size: 8.5px;
  }

  .added p {
    font-size: 6px;
  }

  .logo1 {
    margin-left: 0%;
    height: 20px;
  }
}