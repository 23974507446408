.virtual-container {
    max-width: 60%;
    margin: 0 auto;
    margin-top: 120px;
}

.virtual-content {
    padding: 20px;
}

.plakatok {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}

.plakat-container p {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 20px;
}

.plakat {
    width: 80%;
    padding: 5px;
}

.plakat img {
    max-width: 100%;
}

.docs {
    margin-top: 40px;
}

.virtual-container h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.docs-data {
    margin-bottom: 40px;
    margin-left: 20px;
}

.docs-data h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.virtual-link {
    color: #007bff;
}

.docs-data p {
    margin-bottom: 10px;
}

.impressum {
    margin-top: 40px;
}

.impressum h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.impressum h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.contracts {
    margin-top: 40px;
}

.contracts a {
    display: block;
    margin-bottom: 10px;
    color: #007bff;
}

.contracts p {
    margin-top: 20px;
    font-size: 0.8rem;
    color: #666;
}

.impressum-data {
    display: flex;
}

.impressum-data div:first-child p {
    font-weight: 600;
}

.right-impressum-data {
    margin-left: 25px;
}

.right-impressum-data2 {
    margin-left: 40px;
}

.nyilatkozatok {
    margin-bottom: 20px;
}

.impressum-button2 {
    background-color: transparent;
    border: none;
    margin-top: 5px;
    margin-left: -5px;
    cursor: pointer;
}