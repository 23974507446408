.children-data-container {
  display: flex;
  flex-direction: column;
}

.children-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
}

.children-content {
  position: fixed;
  top: 104px;
  right: 0;
  transform: translateY(-50%);
  width: 86.7%;
  height: 105px;
  background-color: #f1f5f4;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-container {
  position: fixed;
  display: flex;
  left: 42.5%;
  transform: translateX(-50%);
  margin-top: -15px;
}

.filter-container2 {
  position: fixed;
  display: flex;
  left: 43%;
  transform: translateX(-50%);
  margin-top: 35px;
}


.title-sort {
  display: flex;
  flex-direction: column;
}

.child-card {
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 500px;
  text-align: left;
  transition: transform 0.2s;
  background-color: #f0f3f2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pagination-children {
  align-self: center;
  margin-top: 30px;
  margin-bottom: 70px;
}

.child-card:hover {
  transform: scale(1.02);
}

.child-data-container {
  display: flex;
  flex-direction: row;
}

.all-child-data {
  display: flex;
  flex-direction: column;
  margin-left: 22px;
}

.all-child-data p {
  text-align: left;
}

.all-child-data:last-child {
  margin-left: 15%;
}

.edit-delete-buttons {
  opacity: 0;
  position: absolute;
  top: 33px;
  right: 3%;
  transform: translateY(-50%);
  transition: opacity 0.2s;
}

.child-card:hover .edit-delete-buttons {
  opacity: 1;
}

.sorting-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 7%;
  top: 180px;
}

.sort-button {
  width: 100px;
  align-self: flex-end;
}

.sort-options {
  margin-top: 10px;
  margin-right: -72px;
}

.sort-options button {
  margin-left: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: rgb(196, 216, 198);
  color: #fff;
  padding: 5px;
  padding-right: 8px;
  padding-left: 8px;
  cursor: pointer;
}

.sort-options button:hover {
  background-color: rgb(228, 236, 229);
  color: #6b6b6b;
}

.sort-options select {
  padding: 5px;
  border-radius: 6px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.sort-button {
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 6px;
  background-color: transparent;
  color: #3b3b3b;
  cursor: pointer;
}

.sort-button:hover {
  background-color: rgb(215, 226, 218);
  color: #3b3b3b;
}

.add-child-options {
  display: flex;
}

.edit-button {
  background-color: transparent;
  border-radius: 6px;
  margin-right: 8px;
  padding: 3px;
  cursor: pointer;
}

.delete-child-button {
  background-color: transparent;
  color: #000;
  border-radius: 6px;
  padding: 3px;
  cursor: pointer;
}

.edit-button:hover {
  background-color: rgb(115, 177, 86);
  color: #fff;
}

.delete-child-button:hover {
  background-color: #db5050;
  color: #fff;
}

.add-child:last-child {
  margin-left: 20px;
}

.file-input {
  background-color: aqua;
}

::file-selector-button {
  display: inline-block;
  padding: 7px 12px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #8fc0bc;
  border-radius: 4px;
  border: 1px solid #808080;
  transition: background-color 0.3s;
  margin-top: 10px;
  width: 50%;
  cursor: pointer;
}

::file-selector-button:hover {
  background-color: #658a87;
}

.upload-buttons {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.upload {
  background-color: #3da557;
  color: #fff;
  font-weight: bold;
  padding: 8px;
  border-radius: 6px;
  border: none;
  margin-top: 4px;
  cursor: pointer;
}

.upload:hover {
  background-color: #287a3d;
}

.download-template-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;

}

.download-template-button:hover {
  text-decoration: underline;
}

.download-icon {
  margin-right: 5px;
  color: #6b6b6b;
}

.child-card-container2 {
  padding: 6px;
}

.child-card2 {
  background-color: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.data-row {
  display: flex;
}

.data-cell {
  flex: 1;
  padding: 0.75rem;
}

.table-container {
  max-height: 300px;
}

.table {
  width: 70%;
}

.header2 {
  display: flex;
  background-color: #f3f4f6;
  padding-right: 5px;
}

.header2-cell,
.data-cell {
  flex: 1;
  padding: 0.75rem;
  border-bottom: 1px solid #cbd5e0;
  text-align: center;
}

.table-data {
  overflow-y: scroll; 
  max-height: calc(75vh - 8rem);
  margin-right: -16px;
}


@media (max-width: 95em) {
  .sorting-container {
    margin-right: -2%;
  }
}

@media (max-width: 35em) {
  .children-container {
    margin-top: 200px;
  }

  .child-card {
    width: 80%;
  }

  .children-content {
    width: 100%;
    top: 150px
  }

  .input-field {
    width: 280px;
    height: 35px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .filter-container2 {
    flex-direction: column;
    position: none;
    left: 51%;
    transform: translateX(-50%);
    margin-top: -10px;
  }

  .sort-button {
    display: none;
  }

  .datepicker-field {
    height: 30px;
    margin: 0;
  }
}