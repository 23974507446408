@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');


* {
  margin: 0;
  font-family: "Quicksand";
}


html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f1f5f4;
}

.title {
  color: #6b6b6b;
  margin-left: 10px;
  margin-top: -45px;
  font-size: 28px;
}

.title2 {
  display: flex;
  justify-content: center;
  color: #6b6b6b;
  margin-bottom: 15px;
}

.title-not-fixed {
  color: #6b6b6b;
  margin-left: 14%;
  margin-top: 80px;
}

.title3 {
  position: fixed;
  color: #6b6b6b;
  margin-left: 14%;
  margin-top: 80px;
  transition: font-size 0.3s;
}

.scroll .title3 {
  font-size: 21px;
}


.loading {
  text-align: center;
}

.action-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 65%;
  height: 55px;
  background-color: rgb(245, 251, 247);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: 1px solid #ccc;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
}

.action-button {
  background-color: #3e9c7b;
  color: #fff;
  font-weight: bold;
  padding: 8px;
  height: 35px;
  max-width: 200px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 20px;
  border: 1px solid #ccc;
}

.action-button:hover {
  background-color: #087953;
}

.uhu {
  align-self: center;
  width: 80px;
  margin-top: 150px;
}

.uhu {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.error-message {
  color: rgb(236, 35, 35);
}

.test {
  margin-right: auto;
  color: red;
}

.title-back {
  position: fixed;
  top: 97px;
  right: 0;
  transform: translateY(-50%);
  width: 86.7%;
  height: 90px;
  background-color: #f1f5f4;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title-back2 {
  position: fixed;
  top: 30px;
  right: 0;
  transform: translateY(-50%);
  width: 86.7%;
  height: 90px;
  background-color: #f1f5f4;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cookie-modal {
  position: fixed;
  bottom: 0;
  max-width: 600px;
  padding: 20px;
  background-color: rgb(250, 255, 252);
  border: 1px solid #cccccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: 'center';
  z-index: 999;
}

.cookie-buttons {
  margin-top: 15px;
  text-align: right;
}

.accept-cookie{
  background-color: transparent;
  color: #1fa377;
  font-weight: bold;
  width: 90px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #1fa377;
  cursor: pointer;
  margin-left: 10px;
}

.accept-cookie:hover{
  background-color: #1fa377;
  color: #fff;
}

.cookie-policy{
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.cookie-pdf-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.cookie-pdf-modal-content {
  position: relative;
  width: 80%;
  max-width: 800px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.cookie-pdf-modal-content .close {
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
  font-size: 24px;
}

.cookie-pdf-modal-content iframe {
  width: 100%;
  height: calc(100% - 40px);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.uhu {
  animation: pulse 2s infinite;
}

@media (max-width: 100em) {
  .title3 {
    font-size: 22px;
  }

  .scroll .title3 {
    font-size: 16px;
  }
  
}

@media (max-width: 85em) {
  .title {
    margin-left: 23%;
  }
}

@media (max-width: 65em) {
  .title {
    margin-top: 180px;
  }
}

@media (max-width: 35em) {
  .title3 {
   display: none;
  }
  .title{
    display: none;
  }

  .action-panel{
    width: 97%;
  }
 }