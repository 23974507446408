.statistics-container {
  display: flex;
}

.statistics-field {
  width: 32%;
  min-width: 450px;
  margin: auto;
  background-color: #f0f3f2;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 160px;
}

.statistics-container p {
  font-size: 16px;
  color: #333;
  margin: 10px 0;
}

.loading {
  display: flex;
  margin-top: 100px;
  align-items: center;
  justify-content: center;
}

.all-certi {
  font-size: 20px;
  font-weight: bold;
  color: #3e9c7b;
}

.stats {
  display: flex;
  justify-content: space-between;
}

.stats div:last-child {
  margin-right: 20px;
}

.chart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.line-chart {
  width: 30%;
  margin-right: 35px;
}

.pie-chart-label {
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}

@media (max-width: 35em) {
  .statistics-field {
    min-width: 80%;
  }

  .chart-container {
    flex-direction: column;
  }

  .line-chart {
    width: 80%;
  }
}