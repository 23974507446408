.certi-field {
  display: flex;
  margin-left: 25%;
  margin-top: 160px;
  margin-bottom: 100px;
}

.back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 140%;
  max-width: 420px;
  background: #E1EEED;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 0;
}

.large-box {
  width: 287px;
  height: 45px;
  background: #fff;
  color: #6b6b6b;
  border-radius: 5px;
  padding-left: 10px;
  padding-top: 5px;
  border: 1px solid rgb(196, 196, 196);
  resize: none;
}

.large-box-error {
  width: 287px;
  height: 45px;
  background: #fff;
  color: #6b6b6b;
  border-radius: 5px;
  padding-left: 10px;
  padding-top: 5px;
  border: 1px solid red;
  resize: none;
}

.large-box:focus {
  outline: none;
  border: 2px solid rgb(54, 141, 255);
}

.field-picker {
  width: 300px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.field-picker:focus{
  outline: none;
}

.field-picker:first-child {
  margin-top: 20px;
}

.date-picker {
  width: 286px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid rgb(196, 196, 196);
  padding-left: 10px;
}

.error-picker {
  width: 286px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid red;
  padding-left: 10px;
}

.certi-picker {
  margin-bottom: 20px;
}

.date-picker:focus {
  outline: none;
  border: 2px solid rgb(54, 141, 255);
}

.react-datepicker__year-dropdown {
  max-height: 250px;
  overflow-y: auto;
}

.PE {
  display: inline;
  margin-left: 10px;
}

.certi-button,.certi-confirm-button {
  background-color: #078057;
  color: #fff;
  font-weight: bold;
  padding: 8px;
  height: 35px;
  max-width: 200px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.certi-confirm-button {
 margin-top: 0; 
}

.pin-message{
  margin-bottom: 8px;
}

.checkbox {
  align-self: flex-start;
  margin-left: 60px;
  margin-top: 5px;
}

.checkbox:last-child{
  margin-bottom: 20px;
}

.certi-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 460px;
  margin: auto;
  padding: 20px;
  background-color: #E1EEED;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 18%;
}

.certi-modal input {
  width: 25%;
  margin: 0 auto 20px;
  border-radius: 6px;
  border: none;
  padding: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.certi-modal input:focus {
  outline: none;
  border: 1px solid #ccc;
}

.certi-modal button:last-child {
  margin-left: 20px;
}

.text-area-label{
  align-self: flex-start;
}

.back div{
  font-size: 15px;
}

.one-day{
  margin-bottom: 15px;
  margin-right: 10px;
}

.error-border {
  border: 1px solid red;
  border-radius: 6px;
}

@media (max-width: 95em) {
  .certi-field {
    margin-left: 20%;
  }
}

@media (max-width: 85em) {
  .certi-field {
    flex-direction: column;
    margin-left: 40%;
  }
}

@media (max-width: 65em) {
  .certi-modal {
    margin-top: 40%;
    width: 50%;
  }

  .certi-modal button:last-child{
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .certi-field{
    margin-left: 25%;
  }

}

@media (max-width: 35em) {
  .certi-field {
    flex-direction: column;
    margin-left: 5%;
  }
}