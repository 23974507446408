.certification-container {
    display: flex;
    align-items: center;
  }
  
  .certification {
    margin-left: 84px;
    margin-bottom: 100px;
    border: 1.6px solid #6b6b6b;
    height: 760px;
  }

  .add-child{
    background-color: #c3e290cb;
    padding: 8px;
    color: #363636;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #ccc;
    font-weight: 500;
  }

  .add-child:hover{
    background-color: #c4ee81c4;
  }

  .child-save{
    background-color: transparent;
    color: #1fa377;
    font-weight: bold;
    width: 70px;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #1fa377;
    margin-top: 4px;
    cursor: pointer;
  }

  .child-save:hover{
    background-color: #1fa377;
    color: #fff;
  }

  .close-button{
    background-color: transparent;
    color: #eb574d;
    font-weight: bold;
    width: 70px;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #eb574d;
    margin-top: 4px;
    margin-left: 20px;
    cursor: pointer;
  }

  .close-button:hover{
    background-color: #eb574d;
    color: #fff;
  }

  .password-error{
    color: red;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .success-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .log-link{
    text-decoration: none;
  }

  .log-link:hover{
    text-decoration: underline;
  }

  .add-child-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 5%;
    max-width: 420px;
    padding: 20px;
    background-color: #E1EEED;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: 'center';
  }

  .warning {
    color: #eb574d;
    margin-bottom: 12px;
  }

  .pin-error {
    margin-bottom: 10px;
    margin-top: -10px;
    color: #eb574d;
  }

  @media (max-width: 65em) {
    .add-child-modal {
      margin-top: 30%;
    }
  }