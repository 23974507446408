.confirm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirm-box {
    width: 20%;
    padding: 20px;
    background-color: rgb(250, 255, 252);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 80px;
}

.confirm-message {
    margin-top: 20px;
    margin-bottom: 10px;
    color: rgb(1, 95, 1);
}