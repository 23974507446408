.doctor-notes-container {
  display: flex;
  flex-direction: column;
}

.add-note-button {
  width: 230px;
  padding: 7px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #3e9c7b;
  color: white;
  font-weight: bold;
}

.one-note-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(30vw, 1fr));
  grid-gap: 20px;
  width: 65%;
  align-self: center;
  margin-top: 175px;
  margin-bottom: 100px;
}

.add-note-button:hover {
  background-color: #0d7a56;
}

.one-note {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  background-color: #f0f3f1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.one-note:hover {
  transform: scale(1.02);
}

.one-note p {
  margin: 5px;
}

.certi-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.certi-view-button {
  margin-top: 8px;
  border-radius: 6px;
  border: 0.2px solid #d4cfcf;
  padding: 5px;
  background-color: #deeebf;
  font-weight: 500;
  cursor: pointer;
}

.certi-view-button:hover {
  background-color: #cbdfa7;
}

.note-date {
  font-style: italic;
  color: #777;
  font-size: 14px;
  margin-top: 10px;
  margin-left: auto;
}

.notes {
  background-color: #c9ddce;
  padding: 6px;
  border-radius: 6px;
  width: 55%;
  margin-left: 40px;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 90px;
}

.modal-text {
  width: 500px;
  height: 150px;
  background: #fff;
  color: #6b6b6b;
  border-radius: 10px;
  padding-left: 10px;
  padding-top: 5px;
  border: 1px solid rgb(196, 196, 196);
  resize: none;
  margin-left: 45px;
}

.modal-text-error {
  width: 500px;
  height: 150px;
  background: #fff;
  color: #6b6b6b;
  border-radius: 10px;
  padding-left: 10px;
  padding-top: 5px;
  border: 1px solid red;
  resize: none;
}

.modal-text:focus {
  outline: none;
  border: 1px solid #6b6b6b;
}

.save-button {
  padding: 10px;
  background-color: #109669;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 275px;
  border-radius: 6px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.save-button:hover {
  background-color: #0d7a56;
}

.note-content {
  display: flex;
  justify-content: space-between;
}

.add-note-picker {
  width: 300px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 25px;
  font-size: 14px;
}

.filter-section {
  align-self: center;
}

.modal-text,
.modal-text-error {
  width: 500px;
  height: 150px;
  background: #fff;
  color: #6b6b6b;
  border-radius: 10px;
  padding-left: 10px;
  padding-top: 5px;
  border: 1px solid rgb(196, 196, 196);
  resize: none;
}

.modal-text:focus {
  outline: none;
  border: 1px solid #6b6b6b;
}

.save-button {
  padding: 10px;
  background-color: #109669;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 275px;
  border-radius: 6px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.save-button:hover {
  background-color: #0d7a56;
}

.add-note-modal {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 5%;
  max-width: 600px;
  align-items: center;
  padding: 5px;
  background-color: #e1eeed;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.note-certi-modal {
  display: flex;
  justify-content: center;
  max-width: 600px;
  margin: auto;
  margin-top: 4%;
  padding: 10px;
  background-color: #e1eeed;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-date-picker {
  width: 235px;
  height: 40px;
  border: none;
  background: #e4e4e4;
  border-radius: 20px;
  padding-left: 8px;
  color: #3b3b3b;
  text-decoration: none;
  margin-left: 10px;
}

.note-filter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.note-filter-container input {
  margin-top: 15px;
}

.advanced-filters {
  display: flex;
  flex-direction: column;
  margin-top: 82px;
}

.filter-close {
  width: 100px;
  height: 40px;
  border: none;
  background: transparent;
  border-radius: 20px;
  color: #3b3b3b;
  text-decoration: none;
  margin-top: 10px;
}

.filter-close:hover {
  cursor: pointer;
  text-decoration: underline;
}

.filter-button {
  margin-top: 12px;
  margin-left: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #6b6b6b;
}

.filter-button:hover {
  text-decoration: underline;
}

.sorting-container2 {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 20px;
  margin-right: 20%;
}

.add-email {
  display: flex;
  height: 30px;
  margin-bottom: 15px;
  justify-content: center;
}

.email-input {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 4px;
  width: 180px;
}

.warning2 {
  color: #eb574d;
  margin-bottom: 12px;
  font-size: 14.5px;
  margin-left: 1px;
}

.details-container {
  overflow-y: scroll;
  max-height: calc(90vh - 8rem);
}

.pagination-notes{
  align-self: center;
  margin-top: -65px;
  margin-bottom: 75px;
}

.note-data-container {
  display: flex;
}

.bold-text {
  font-weight: 600;
}

@media (max-width: 65em) {
  .one-note {
    width: 70%;
  }

  .add-note-modal {
    width: 80%;
    margin-top: 30%;
  }

  .modal-text {
    width: 390px;
  }

  .note-certi-modal {
    margin-top: 30%;
    width: 85%;
  }
}

@media (max-width: 45em) {
  .one-note {
    width: 80%;
    font-size: 14px;
  }
}

@media (max-width: 35em) {
  .one-note-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 225px;
    margin-bottom: 100px;
  }
}