
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .modal-content {
    text-align: center;
    background-color: rgb(250, 255, 252);
    margin: 15% auto;
    border: 1px solid #888;
    width: 15%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .modal button {
    margin-top: 12px;
  }