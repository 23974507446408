.reset-container {
    display:flex;
    flex-direction: column;
    align-items: center;
}

.reset-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
    background-color: #E1EEED;
}

.reset-form p{
    align-self: self-start;
}