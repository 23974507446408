
.template-modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  
  .template-modal-content {
    text-align: center;
    background-color: rgb(250, 255, 252);
    margin: 10% auto;
    border: 1px solid #888;
    border-radius: 6px;
    width: 26%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 15px;
    overflow: auto;
  }
  
  .errors-list {
    max-height: 200px;
    overflow-y: auto;
  }
  

  .template-modal-content p {
    margin-top: 5px;
    text-align: start;
  }

  .template-modal-content button {
    margin-top: 10px;
  }