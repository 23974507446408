.check-certi-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    text-align: center;
}

.check-certi-container p{
    max-width: 695px;
    padding: 6px;
}

.check-certi-container button {
    margin-top: 6px;
}

.name-logo{
    width: 230px;
}

.shield{
    width: 90px;
}

.verification-message{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.verification-message p{
    padding: 5px;
}

.verification{
    width: 100px;
    margin-bottom: 15px;
}

@media (max-width: 35em) {
    .check-certi-container {
        margin-top: 50px; /* Csökkentett margó mobil méretű kijelzőn */
    }

    .check-certi-container p {
        max-width: 90%; /* Szélesség a teljes mobilkijelző szélességének 90%-a */
    }

    .name-logo {
        width: 150px; /* Kisebb logó méret mobil nézetben */
    }

    .shield {
        width: 60px; /* Kisebb pajzs méret mobil nézetben */
    }

    .verification-message {
        margin-top: 10px; /* Csökkentett margó a verifikációs üzenetnél */
    }

    .verification-message p {
        padding: 3px; /* Kisebb padding a verifikációs üzenetnél */
    }

    .verification {
        width: 80px; /* Kisebb verifikációs méret mobil nézetben */
        margin-bottom: 10px; /* Csökkentett margó a verifikáció alatt */
    }
}
