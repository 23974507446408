.contact-container {
    display: flex;
}

.contact-field {
    display: flex;
    justify-content: space-between;
    width: 41%;
    min-width: 450px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(180, 236, 236, 0.15);
    padding: 40px;
    border-radius: 10px;
    margin-top: 160px;
    border: 1px solid #dfdede;
}

.contact-container-left {
    min-width: 150px;
}

.contact-container-left p {
    font-size: 15px;
}

.contact-container-left p {
    margin-top: 5px;
}

.contact-title {
    margin-top: 20px;
}

.contact-container-right h4 {
    margin-bottom: 10px;
}

.conatct-check {
    margin-bottom: 10px;
}

.conatct-check label {
    margin-left: 8px;
}

.contact-container-right {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #bebebe;
    height: fit-content;
    width: 62%;
    min-width: 295px;
}

.open {
    display: flex;
}

.bold-p {
    font-weight: 600;
}

.contact-text {
    width: 95%;
    height: 140px;
    background: #fff;
    color: #6b6b6b;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 5px;
    border: 1px solid rgb(196, 196, 196);
    resize: none;
}

.contact-text-error {
    width: 95%;
    height: 140px;
    background: #fff;
    color: #6b6b6b;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 5px;
    border: 1px solid red;
    resize: none;
}

.contact-text:focus {
    outline: none;
    border: 1px solid #6b6b6b;
}

.help-note {
    font-weight: 600;
    margin-bottom: 20px;
}

.help-select {
    margin-left: auto;
    margin-right: auto;
}

.help-select-error {
    margin-left: auto;
    margin-right: auto;
    border: 1px solid red;
}

@media (max-width: 65em) {
    .contact-field {
        flex-direction: column;
        width: 80%;
    }

    .contact-container-right {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        width: 72%;
    }
}