.request-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    margin-left: 21%;
    margin-right: 21%;
    margin-top: 185px;
    padding-bottom: 85px;
}

.request-box {
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
    text-align: left;
    transition: transform 0.2s;
    background-color: #f0f3f2;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 520px;
}

.request-box:hover {
    transform: scale(1.02);
}

.accept-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
}

.request-date {
    text-align: end;
    font-size: 13px;
    color: #6b6b6b;
    margin-top: 5px;
}

.name-container2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    background-color: #c4dbd2;
    margin-bottom: 8px;
}

.name2 {
    margin-left: 10%;
}

.reject-button {
    background-color: #ca4e4e;
    color: #fff;
    font-weight: bold;
    padding: 8px;
    height: 35px;
    max-width: 200px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 20px;
    border: 1px solid #ccc;
}

.reject-button:hover {
    background-color: #7a0d0d;
}

.prev-data2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.left-data2 {
    display: flex;
    flex-direction: row;
    padding: 4px;
    margin-top: 5px;
}

.right-data2 {
    display: flex;
    flex-direction: row;
    padding: 4px;
    margin-top: 5px;
}

.request-data-names {
    text-align: right;
    font-weight: 600;
}

.request-datas {
    margin-left: 4px;
}

.accept-buttons-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 6px;
    margin-bottom: 10px;
}

.request-chechkbox {
    width: 3.5%;
}

.data-container2 button:first-child {
    margin-right: 15px;
}

.filter-buttons {
    position: fixed;
    display: flex;
    left: 42.5%;
    transform: translateX(-50%);
    margin-top: 30px;
}

.request-filter-button {
    background-color: transparent;
    border: none;
    padding: 8px 16px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 6px;
}

.request-filter-button:focus,
.request-filter-button:hover {
    background: #94be9e;
}

.request-filter-button.active {
    background-color: #3e9c7b;
    color: #fff;
}

.request-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 15%;
    margin: auto;
    padding: 20px;
    background-color: #E1EEED;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 18%;
}

.request-modal input {
    width: 30%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.action-button2 {
    background-color: #3e9c7b;
    color: #fff;
    font-weight: bold;
    padding: 8px;
    height: 35px;
    max-width: 250px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 20px;
    border: 1px solid #ccc;
}

.action-button2:hover {
    background-color: #087953;
  }

  .request-text {
    width: 90%;
    height: 45px;
    background: #fff;
    color: #6b6b6b;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 5px;
    margin-bottom: 20px;
    border: 1px solid rgb(196, 196, 196);
    resize: none;
  }
  
  .request-text:focus {
    outline: none;
    border: 2px solid rgb(54, 141, 255);
  }

  .request-alert-button{
    background-color: transparent;
    color: #1fa377;
    font-weight: bold;
    width: 70px;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #1fa377;
    margin-top: 20px;
    cursor: pointer;
  }

  .request-alert-button:hover{
    background-color: #1fa377;
    color: #fff;
  }

  .error-message2 {
    color: red;
    margin-bottom: 15px;
  }

  @media (max-width: 107em) {
    .request-container {
        gap: 20px;
        margin-left: 15%;
        margin-right: 15%;
    }
  }