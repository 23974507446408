.header {
  background-color: rgb(231, 240, 234);
    color: white;
    height: 52px;
    display: flex;
    justify-content: right;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .profile-container {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 8%;
  }
  
  .name {
    text-align: right;
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .profilepic {
    width: 44px;
    height: 38px;
    border-radius: 30px;
    margin-right: 20px;
    cursor: pointer;
  }
  
  .profilepic-container {
    position: relative;
  }
  
  .profile-item {
    margin: 5px 0;
    cursor: pointer;
  }

.header-logo {
  width: 100px;
  margin-right: auto;
  margin-left: 20px;
}

.help-link {
  color: #6b6b6b;
  text-decoration: none;
  margin-right: 30px;
}

  @media (max-width: 65em) {
    .profile-container {
      margin-right: 3%;
    }
}