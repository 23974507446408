.registration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.additional {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.additional p {
  width: 600px;
  text-align: center;
  padding: 8px;
}

.reg-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  background-color: rgb(243, 253, 247);
  margin-top: 30px;
}

.reg-form label {
  align-self: flex-start;
  margin-bottom: 10px;
  margin-left: 10px;
}

.reg-validation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.reg-input {
  padding: 8px;
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.reg-input-error {
  padding: 8px;
  width: 70%;
  border: 1px solid red;
  border-radius: 5px;
}

.reg-input:focus {
  outline: none;
  border: 1px solid #3b3b3b;
}

.reg-button {
  padding: 10px;
  background-color: #078057;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 200px;
  border-radius: 10px;
  font-weight: bold;
  margin-bottom: 15px;
}

.reg-button:hover {
  background-color: #218838;
}

.page-button {
  padding: 10px;
  background-color: #078057;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 200px;
  border-radius: 10px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 25px;
}

.checkbox-group input {
  margin-right: 5px;
  margin-top: 20px;
}

.spec-container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.specialization {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-left: 45px;
  margin-top: 5px;
  margin-bottom: 5px;
}


.optional-button{
  background-color: transparent;
  border: none;
  margin-top: 20px;
  font-size: 16px;
  color: #3b3b3b;
  cursor: pointer;
}

.optional-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  margin-top: 20px;
}

.result-container{
  display: flex;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  width: 600px;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: rgb(250, 255, 252);
}

.result-container p {
  margin-bottom: 5px;
}

.identification-container {
  border: 1px solid #6b6b6b;
  border-radius: 6px;
  padding: 5px;
  margin-top: 20px;
}

.reg-file-upload-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.reg-file {
  padding: 7px 12px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #8fc0bc;
  border-radius: 4px;
  border: 1px solid #808080;
  transition: background-color 0.3s;
  margin-top: 10px;
  cursor: pointer;
  margin-right: 5px;
}

.reg-file-name {
  border: none;
  background-color: transparent;
  width: 30%;
}

.identification-button{
  border: 1px solid #6b6b6b;
  border-radius: 6px;
  padding: 5px;
  margin-bottom: 10px;
  width: 37%;
  margin-left: auto;
  margin-right: auto;
}

.identification{
  text-decoration: none;
  color: #3b3b3b;
}

.doctor-file-p{
 margin-left: 20px;
 margin-top: 10px;
 margin-bottom: 10px;
}
