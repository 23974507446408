.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  left: 0;
  top: 52px;
  min-width: 13%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 0;
}

.menu-item {
  width: 100%;
  height: 48px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #6b6b6b;
  text-decoration: none;
  background-color: rgb(245, 251, 247);
  border-bottom: 1px solid #ccc;
}

.disabled {
  width: 100%;
  height: 48px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #6b6b6b;
  text-decoration: none;
  background-color: rgb(245, 251, 247);
  border-bottom: 1px solid #ccc;
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.menu-item:last-child{
  border-radius: 0 0 10px 0;
}

.menu-item:hover {
  background: #94be9e;
  color: white;
}

.selected {
  background-color: #3e9c7b;
  color: white;
  border-right: 2px solid #b8b8b8;
}

@media (max-width: 65em) {
  .menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
    z-index: 100;
    border-radius: 0;
  }

  .menu-item {
    min-width: 80px;
    text-align: center;
    font-size: 15px;
  }
}

@media (max-width: 35em) {
  .menu {
    top: 60px;
    height: 30px;
  }

  .menu-item {
   font-size: 13px;
  }
}
